exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-templates-archived-archive-js": () => import("./../../../src/templates/archived/archive.js" /* webpackChunkName: "component---src-templates-archived-archive-js" */),
  "component---src-templates-blogs-categories-js": () => import("./../../../src/templates/blogs/categories.js" /* webpackChunkName: "component---src-templates-blogs-categories-js" */),
  "component---src-templates-blogs-post-js": () => import("./../../../src/templates/blogs/post.js" /* webpackChunkName: "component---src-templates-blogs-post-js" */),
  "component---src-templates-works-work-js": () => import("./../../../src/templates/works/work.js" /* webpackChunkName: "component---src-templates-works-work-js" */)
}

